.sustainability-page {
  max-width: 1240px;
  margin: 0 auto;
  margin-bottom: 48px;

  p {
    font-size: 18px;
  }

  &__intro {
    padding: 20px;
    line-height: 1.4;
    letter-spacing: 1.1px;

    @media (min-width: 767px) {
      padding: 48px;
    }
  }

  @media (min-width: 767px) {
      padding-bottom: 64px;
  }

  &__hero-img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }

  &__title {
    padding: 40px 20px 20px;
    color: black;

    @media (min-width: 767px) {
      padding-left: 40px;
    }

    @media (min-width: 1024px) {
      padding: 150px 50px 30px;
    }
  }

  &__items {
    display: flex;
    margin: 0 auto;
    padding: 10px;
    align-items: center;
    flex-direction: column;
  }

  &__item {
    padding: 10px;
    color: black;
    min-width: 80%;
    display: flex;
    align-items: center;

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
  
    @media (min-width: 768px) {
        justify-content: space-around;
        margin-bottom: 48px;
    }

    div {
      @media (min-width: 768px) {
        max-width: 50%;
      }
    }

    img {
      display: none;
      width: 250px;
      height: 250px;
      border-radius: 100%;

      @media (min-width: 768px) {
        display: block;
      }
    }

    h2 {
      line-height: 1.2;
      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      font-weight: bold;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        font-size: 1.6rem;
      }
    }

    ul {
      padding-left: 24px;
      margin-bottom: 48px;

      li {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 24px;
        list-style-type: disc;
        line-height: 1.2;
        letter-spacing: 1.1px;

        @media (min-width: 768px) {
          margin-bottom: 8px;
        }
      }
    } 
  }

  &__cta {
    color: white;
    padding: 10px;
    background-color: black;
    text-decoration: none;
    margin-left: 32px;
    display: inline-flex;

    @media (min-width: 768px) {
      display: none;
    }
    

    svg {
      margin-left: 8px;
    }
  }
}
