body {
  font-family: 'Montserrat', sans-serif;

  h2 {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-weight: bold;

    @media (min-width: 767px) {
      font-size: 35px;
    }
  }
}

