.home-page {
  &__items {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 10px;
    margin: 0 auto;
    justify-content: center;
    max-width: 1240px;

    @media (min-width: 767px) {
      padding-top: 150px;
      padding-bottom: 64px;
    }
  }

  &__item {
    margin: 25px;
  }

  &__item-img {
    height: 250px;
    width: 250px;
  }

  &__item-box {
    height: 220px;
    width: 220px;
    border: 15px solid black;
    position: relative;

    &:hover {
      border-color: grey;

      h3 {
        color: grey;
      }
    }
  }

  &__item-box-title {
    color: black;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    position: absolute;
    bottom: 15px;
    right: 18px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.1px;
  }
}
