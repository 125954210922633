:root {
  color-black: #000;
  color-white: #fff;
  color-charcoal: #1a1a1a;
  color-dark-grey: #383838;
  color-grey: #828282;
  color-lightest-grey: #f6f6f6;
  color-light-grey: #dbdbdb;
  color-purple: #704cff;
}
