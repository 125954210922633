.project-page {
  max-width: 1240px;
  margin: 0 auto;
  margin-bottom: 150px;

  @media (min-width: 767px) {
      padding-bottom: 64px;
  }

  &__title {
    padding: 80px 20px 20px;
    color: black;

    @media (min-width: 767px) {
      padding-left: 40px;
    }

    @media (min-width: 1024px) {
      padding: 150px 50px 30px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 10px;
    justify-content: center;

    li {
      position: relative;
      letter-spacing: 1.1px;
      line-height: 1.4;
    }
  }

  &__item {
    padding: 10px;
    transition: all 1s ease-out;
    
    div {
      @media (min-width: 767px) {
        padding: 24px;
        display: none;
      }
    }

    &:hover {
      @media (min-width: 767px) {
        div {
          display: block;
        }
      }
    }

    @media (min-width: 767px) {
      width: calc(50% - 40px);
    }
  }

  &__item-content {
    background-color: rgba(#000000, 0.5);
    padding: 10px 0;
    z-index: 1;
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;

      @media (min-width: 767px) {
        
        background-color: rgba(#000000, 0.8);
        margin-top: 0;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
      }
  }

  &__item-img {
    width: 100%;
    position: relative;
    z-index: 0;
  }

  &__item-title {
    padding: 0 0 10px 8px;
    color: white;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-weight: bold;
  }

  &__item-subtext {
    padding: 0 0 0 10px;
    color: white;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }
}
