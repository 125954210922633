.nav {
  width: calc(100vw - 40%);
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 2;
  display: none;

  @media (min-width: 1023px) {
    background-color: white;
    top: 0;
    width: 100%;
    display: block;
    height: auto;
  }

  &__logo {

    @media (min-width: 1023px) {
      width: 50px;
      height: 50px;
    }
  }

  &--is-open {
    display: block;

    .nav__hamburger-icon {
      cursor: pointer;

      svg {
        fill: white;
      }
    }
  }

  &__hamburger-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    @media (min-width: 1023px) {
      display: none;
    }

    svg {
      margin: 10px;
      width: 35px;
      height: 35px;
      fill: black;
    }
  }

  &__items {
    padding: 60px 10px 10px;

    @media (min-width: 1023px) {
      display: flex;
      padding: 10px;
      align-items: flex-end;

    }
  }

  &__item {
    padding: 16px;

    &--is-logo {
      display: none;

      @media (min-width: 1023px) {
        display: inline-block;
      }
    }
  }

  &__item a {
    color: white;
    font-size: 24px;
    text-decoration: none;

    @media (min-width: 1023px) {
      color: black;
    }

    &:hover {
      @media (min-width: 1023px) {
        color: grey;
      }
    }

    &:focus {
      outline: none;
    }
  }
}
