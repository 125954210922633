.about-page {
  max-width: 1240px;
  margin: 0 auto;
  padding: 80px 10px;

  ul {
      padding-left: 36px;
      margin-bottom: 48px;

      li {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 24px;
        list-style-type: disc;
        line-height: 1.2;
        letter-spacing: 1.1px;

        @media (min-width: 768px) {
          margin-bottom: 8px;
        }
      }
    } 

  @media (min-width: 767px) {
    padding: 80px 40px;
  }

  &__hero-img {
    width: 100%;
  }

  &__title {
    padding: 40px 20px 20px;
    color: black;
  }

  &__sub-title {
    padding: 40px 20px 20px;
    color: black;
    font-size: 1.6rem;
    text-transform: uppercase;
  }

  &__text {
    padding: 20px;
    color: black;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.1px;
    line-height: 1.4;
  }

  &__cta {
    color: white;
    padding: 10px;
    background-color: black;
    text-decoration: none;
    margin: 20px;
    display: inline-flex;

    svg {
      margin-left: 8px;
    }
  }

  &__chas-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (min-width: 767px) {
      align-items: center;
      flex-direction: row-reverse;
    }

    p {
      width: 70%;
    }

    img {
      width: 100px;
      height: 100px;
      padding-left: 20px;

      @media (min-width: 767px) {
        width: 150px;
        height: 150px;
        padding: 0;
      }
    }
  }
}
