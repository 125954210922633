.footer {
  background-color: #ccc;
  display: flex;
  flex-direction: column-reverse;
  padding: 48px;

  @media (min-width: 1023px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    padding: 48px 24px 12px;
  }

  &__website-by-logo {
    width: 20px;
    height: 20px;
  }

  p, a {
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    letter-spacing: 1.1px;
    text-align: center;
    padding: 10px;
    text-decoration: none;

    @media (min-width: 1023px) {
      text-align: left;
    }
  }

  a {
    display: block;

    &:hover {
      color: white;
    }

    &:focus {
      outline: none;
    }
  }

    .blink {
      font-size: 10px;
    }
}