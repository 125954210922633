:root {
  --mobile-portrait: 320px;
  --mobile-landscape: 480px;
  --tablet-portrait: 768px;
  --tablet-landscape: 1024px;
  --desktop: 1440px;
  --wide-desktop: 1680px;
  --extra-wide-desktop: 2560px;
}

@custom-media --from-mobile-portrait (min-width: 320px);
@custom-media --from-mobile-landscape (min-width: 480px);
@custom-media --from-tablet-portrait (min-width: 768px);
@custom-media --from-wide-tablet-portrait (min-width: 980px);
@custom-media --from-tablet-landscape (min-width: 1024px);
@custom-media --desktop (max-width: 1279px);
@custom-media --from-desktop (min-width: 1440px);

