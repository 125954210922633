.contact-page {
  max-width: 1240px;
  margin: 0 auto;
  padding: 80px 10px;
  min-height: calc(100vh - 260px);

  @media (min-width: 767px) {
    padding: 80px 40px;
  }

  &__hero-img {
    width: 100%;
  }

  &__title {
    padding: 40px 20px 20px;
  }

  &__contact {
    display: block;
    padding: 20px;
    color: black;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.1px;
    line-height: 1.4;
    text-decoration: none;

    @media (min-width: 767px) {
      font-size: 18px;
    }

    &:hover {
      color: grey;
    }

    svg {
      padding-right: 5px;

      @media (min-width: 767px) {
        padding-right: 15px;
      }
    }
  }
}
